$color-danger: #de4437;
$color-error: #73231d;

// @import "font-awesome";

@import 'common/variables';
@import 'devise/payment_data';
@import '~toastr/build/toastr.min';

.field_with_errors {
  color: $color-danger;
  flex-grow: 1;
}

.field_with_errors {
  input {
    border-color: $color-danger;
 }
}

.form-alert p {
  color: $color-error;
  font-size: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.form-control,
.custom-select {
  height: 3.23077rem;
}

.form-control-sm,
.custom-select-sm {
  height: 2.53846rem;
}

.form-control-lg,
.custom-select-lg {
  height: 3.84615rem;
}

.registration form {
  max-width: 600px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
