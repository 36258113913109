$monochrome-black: #3D454D;
$monochrome-gray: #BDC5D1;
$original-blue: #377DFF;
$light-blue: #F4F7FC;

$control-height: 48px;

.StripeElement {
  height: $control-height;
  border: 1px solid $cloudy-blue;
  border-radius: 4px;
  background-color: white;
  padding: 15px 12px;
}

.StripeElement--focus {
  border-color: $lightish-blue;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
}

.flashes .alert {
  border-radius: 4px;
}

.flashes .alert.alert-error {
  border: solid 1px #fa755a;
  color: #fa755a;
}



.payment {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-direction: row;

  &-logo {
    width: 100%;
    margin-bottom: 4rem;
    max-height: 2.5rem;
    object-fit: contain;

    &.lbm {
      max-height: 1.25rem;
    }
  }

  &-image {
    img {
      width: 100%;
    }
  }

  &-form {
    .btn-group {
      position: relative;
      display: inline-flex;
      vertical-align: middle;

      .btn-check {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
      }

      .btn-outline-primary {
        border-color: $cloudy-blue;
        color: $solid-dark;

        &:hover {
          background-color: $pale-grey;
          color: $lightish-blue;
        }
      }

      .btn-check:checked + .btn-outline-primary {
        border-color: $lightish-blue;
        background-color: $pale-grey;
        color: $lightish-blue;
      }
    }

    .btn {
      border-radius: 0;
    }

    input,
    select {
      height: $control-height;
      border-color: $cloudy-blue;
      border-radius: 4px;

      &:focus {
        border-color: $lightish-blue;
      }
    }

    label {
      margin-bottom: 0;
      color: $cloudy-blue;
    }

    .discount {
      width: 50%;
    }

    .actions {
      margin-top: 50px;
    }

    .btn:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .btn:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.switcher {
  display: inline-flex;
  gap: 24px;

  input[type='checkbox'] {
    display: none;

    &:not(:checked) ~ .caption:nth-of-type(1) {
      color: $lightish-blue;
    }

    &:checked ~ .caption:nth-of-type(2) {
      color: $lightish-blue;
    }

    &:checked ~ .toogle-switch:after {
      transform: translate(16px, 0px);
    }
  }

  .caption {
    color: $steel;
  }

  .toogle-switch {
    position: relative;
    width: 40px;
    height: 24px;
    border: 1px solid $lightish-blue;
    border-radius: 12px;
    background-color: $lightish-blue;
    transition: border-color .2s ease, background-color .2s ease;
    min-width: 40px;
    cursor: pointer;

    &:after {
      position: absolute;
      top: 1px;
      left: 1px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $white;
      transition: transform .2s ease;
      content: '';
    }
  }
}

.payment-plans {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 8px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: map-get($grid-breakpoints, 'xl')) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: map-get($grid-breakpoints, 'hd')) {
    grid-template-columns: repeat(5, 1fr);
  }

  &-item {
    input[type='radio'] {
      display: none;

      &:checked ~ label {
        background-color: $lightish-blue;
        color: $white;

        small {
          color: rgba($white, .5);
        }
      }
    }

    label {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      border: 1px solid $lightish-blue;
      border-radius: 4px;
      padding: 8px;
      color: $lightish-blue;
      text-align: center;
      gap: 8px;
      min-height: 88px;
      cursor: pointer;

      span {
        font-size: 14px;
      }

      strong {
        font-size: 18px;
      }

      small {
        color: $cloudy-blue;
      }
    }
  }
}

@for $i from 1 through 8 {
  .gap-#{$i} {
    gap: calc(.25rem * #{$i});
  }

  .row-gap-#{$i} {
    row-gap: calc(.25rem * #{$i});
  }

  .col-gap-#{$i} {
    column-gap: calc(.25rem * #{$i});
  }
}
