// Colors
$color-jungle-green: #2cc185;
$gray-bg-color: #f7f7f7;
$border-color: #00000045;
$transition-duration: .3s;
$text-color: #555;

// theme overrides
$font-size-base: 1rem;
$font-size-sm: ($font-size-base * .875) !default;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-root: .875 !default;
$paragraph-size: 1rem !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xlg: 1024px,
  xl: 1200px,
  xxl: 1440px,
  3xl: 1600px,
  hd: 1900px
);

$container-max-widths: (
  lg: 1140px,
  xl: 1600px
);

$blue-100: #ebeeff;
$blue-200: #c7d1ff;
$blue-300: #a1b4ff;
$blue-400: #7698ff;
$blue: #377dff;
$blue-600: #446fd7;
$blue-700: #4962b1;
$blue-800: #48558c;
$blue-900: #444969;

$grey-100: #fcfcfc;
$grey-200: #f3f3f3;
$grey-300: #e9e9e9;
$grey-400: #e0e0e0;
$grey: #d7d7d7;
$grey-600: #bababa;
$grey-700: #9f9f9f;
$grey-800: #9f9f9f;
$grey-900: #6a6a6a;

$green-100: #e4fff9;
$green-200: #bbf2e6;
$green-300: #91e5d1;
$green-400: #60d7bd;
$green: #00c9a7;
$green-600: #28b295;
$green-700: #359b84;
$green-800: #3c8573;
$green-900: #3e6f62;

$light-blue-100: #efffff;
$light-blue-200: #beeaf1;
$light-blue-300: #8cd3e8;
$light-blue-400: #58bbe4;
$light-blue: #00b7fc;
$light-blue-600: #09a2e0;
$light-blue-700: #0e8ec4;
$light-blue-800: #107aa9;
$light-blue-900: #10678f;

$red-bright: #FF3737;
$red-100: #ffeced;
$red-200: #fcc4c7;
$red-300: #f69ca2;
$red-400: #ec727e;
$red: #de425b;
$red-600: #c44053;
$red-700: #ab3d4b;
$red-800: #933944;
$red-900: #7b353c;

$orange-100: #fae8d7;
$orange-200: #ffd6aa;
$orange-300: #ffc57d;
$orange-400: #ffb34d;
$orange: #ffa200;
$orange-600: #f69200;
$orange-700: #f18a00;
$orange-800: #e87a00;
$orange-900: #dd6a00;

$primary-100: $blue-100;
$primary-200: $blue-200;
$primary-300: $blue-300;
$primary-400: $blue-400;
$primary: $blue;
$primary-600: $blue-600;
$primary-700: $blue-700;
$primary-800: $blue-800;
$primary-900: $blue-900;

$info-100: $light-blue-100;
$info-200: $light-blue-200;
$info-300: $light-blue-300;
$info-400: $light-blue-400;
$info: $light-blue;
$info-600: $light-blue-600;
$info-700: $light-blue-700;
$info-800: $light-blue-800;
$info-900: $light-blue-900;

$secondary-100: $grey-100;
$secondary-200: $grey-200;
$secondary-300: $grey-300;
$secondary-400: $grey-400;
$secondary: $grey;
$secondary-600: $grey-600;
$secondary-700: $grey-700;
$secondary-800: $grey-800;
$secondary-900: $grey-900;

$success-100: $green-100;
$success-200: $green-200;
$success-300: $green-300;
$success-400: $green-400;
$success: $green;
$success-600: $green-600;
$success-700: $green-700;
$success-800: $green-800;
$success-900: $green-900;

$danger-100: $red-100;
$danger-200: $red-200;
$danger-300: $red-300;
$danger-400: $red-400;
$danger: $red;
$danger-600: $red-600;
$danger-700: $red-700;
$danger-800: $red-800;
$danger-900: $red-900;

$warning-100: $orange-100;
$warning-200: $orange-200;
$warning-300: $orange-300;
$warning-400: $orange-400;
$warning: $orange;
$warning-600: $orange-600;
$warning-700: $orange-700;
$warning-800: $orange-800;
$warning-900: $orange-900;

$white: #ffffff;

$steel: #77838f;
$steel-64: rgba($steel, .64);
$steel-72: rgba($steel, .72);
$dark-coral: #dc443d;
$dark-coral-hover: #ca3e38;
$cloudy-blue: #bdc5d1;
$cloudy-blue-24: rgba($cloudy-blue, .24);
$cloudy-blue-48: rgba($cloudy-blue, .48);
$cloudy-blue-64: rgba($cloudy-blue, .64);
$cloudy-blue-72: rgba($cloudy-blue, .72);
$very-light-blue: #e7eaf3;
$mango: #f9ae34;
$mango-8: rgba($mango, .08);
$bluish-purple: #7f30f0;
$turquoise: #01c9a7;
$attention-green: #29BA6C;
$attention-green-hover: #17B467;
$green-light: #94DDB6;
$green-pale: #DFF5E9;
$attention-orange: #ff8e3d;
$lightish-blue: #377dff;
$pale-blue: #A5C4FF;
$lightish-blue-hover: #3273eb;
$additional-blue: #E1ECFF;
$light-gray: #e9eef8;
$pale-grey: #f4f7fc;
$disabled-grey: #D5DAE2;
$dark-grey: #535c65;
$alt-grey: #828e9a;
$black: #222222;
$black-light: #2A2B2E;
$solid-dark: #3d454d;
$solid-dark-16: rgba($solid-dark, .16);
$purple: #aa00ff;
$layout-bg: #fbfbfb;
$floral-white: #fff9ef;

$yellow-bg: #ffeee2;
$attention-orange: #ff8e3d;

$red-bg: #FFE1E1;

$box-shadow-dropdown: 0 8px 16px 0 rgba($steel, .24);
$box-shadow-dropdown-calendar: 0 8px 24px 0 rgba($cloudy-blue, .48);

$xxl-sidebar-part-width: 240px;
$sm-sidebar-part-width: 100px;
